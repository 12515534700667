import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ClientDetailComponent } from './client-detail/client-detail.component';
import { RouterModule } from '@angular/router';
import { rootRoutesConfig } from './route';
import { ClientListComponent } from './client-list/client-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';

// filter
import {GrdFilterPipe} from '../app/utils/grd-filter-pipe';

@NgModule({
  declarations: [
    AppComponent,
    ClientDetailComponent,
    ClientListComponent,
    GrdFilterPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(rootRoutesConfig,{useHash:false}),
    ToastrModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
