import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';
import { Validators, FormBuilder, FormGroup} from '@angular/forms';
import { CustomValidator } from '../utils/custom.validator';
import { GenericValidator } from '../common/validation/generic-form-validator';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html'
})
export class ClientDetailComponent implements OnInit {
  public errors: any[] = [];
  public form: FormGroup;
  isLoading = false;
  labelButton = "GRAVAR";
  isLoaderActive = true;
  customer: any;
  isUpdate = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private dataService: DataService,
              private fb: FormBuilder,
              private toastr: ToastrService) {

      this.validationMessages = {
          name: {
                 required: 'Nome Deve Ser Informado.',
                 minlength: 'Nome Precisa Mínimo 3 Caracteres',
                 maxlength: 'Nome Precisa ter no Máximo 200 Caracteres'
                },
          city: {
              required: 'Cidade Deve Ser Informada.',
              minlength: 'Cidade Precisa Mínimo 3 Caracteres',
              maxlength: 'Cidade Precisa ter no Máximo 2000 Caracteres'
          },
          email: {
            required: 'Email Deve Ser Informado.',
            minlength: 'Email Precisa Mínimo 3 Caracteres',
            maxlength: 'Email Precisa ter no Máximo 500 Caracteres',
          }
    };

    this.genericValidator = new GenericValidator(this.validationMessages);

  }

  displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;

  ngOnInit() {
    let id = this.route.snapshot.params.id;
    if (id != null) {
      this.isLoaderActive = true;
      this.isUpdate = true;
      this.getCustomer(id);
    }
    else {
      this.isUpdate = false;
      this.isLoaderActive = false;
    }

    this.labelButton = "GRAVAR";
    this.isLoading = false;
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
      email:['', [Validators.required, Validators.minLength(2), Validators.maxLength(500), CustomValidator.EmailValidator]],
      city: ['',[Validators.required, Validators.minLength(2), Validators.maxLength(200)]]
    });
  }

  getCustomer(id: string) {
    this.isUpdate = true;
    this.dataService.getCustomerById(id).pipe(first()).subscribe(
      data => {
        this.customer = data;
        this.form.setValue({
          name: data.name,
          email: data.email,
          city: data.city
        });
        this.isLoaderActive=false;
      },
      error => {
        this.errors = error.error
        this.isLoaderActive = false;
      });
      
  }

  
  onSubmit() {
    this.labelButton = "AGUARDE...";
    this.isLoading = true;

    this.displayMessage=this.genericValidator.processMessages(this.form);

    if (this.form.valid && this.form.dirty)
    {
      if (this.isUpdate)
      {
        this.dataService.updateCustomer(this.form.value,this.customer.id)
          .pipe(first())
          .subscribe(
            data => {
              this.toastr.success("Cliente Alterado Com Sucesso");
              this.router.navigateByUrl('');
            },
            error => {
              this.errors = error.error
              this.errors.forEach(element => {
                this.toastr.error(element.message);
              });
              console.log(error);
              this.isLoading = false;
              this.labelButton = "GRAVAR";
            }); 
      }
      else
      {
      this.dataService.createCustomer(this.form.value)
        .pipe(first())
        .subscribe(
          data => {
            this.router.navigateByUrl('');
          },
          error => {
            console.log("Bugou");
            this.errors = error.error
            console.log(error);
            this.isLoading = false;
            this.labelButton = "GRAVAR";
          });
        }
    }
    else
    {
      this.isLoading=false;
      this.labelButton="GRAVAR"
    }
  }
}
