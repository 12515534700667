import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Ui } from '../utils/ui';
import { Router } from '@angular/router';


@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  providers: [Ui,DataService]
})
export class ClientListComponent implements OnInit {
  public errors: any[] = [];
  isLoaderActive = true;
  customers: any;
  idToRemove: any;

  constructor(private router: Router,
              private dataService: DataService,
              private ui:Ui,
              private toastr: ToastrService) { 

  }

  ngOnInit() {
    this.isLoaderActive=true;
    this.getCustomers();
  }

  delete(event) {
    event.preventDefault();
    this.isLoaderActive=true;
    this.dataService.deleteCustomer(this.idToRemove)
      .subscribe(() => {
          this.toastr.success("Exclusão de Cliente Efetuada!");
          this.router.navigateByUrl('');
          this.ngOnInit();
      },
      error => {
        this.isLoaderActive=false;
        this.errors = error.error
        this.errors.forEach(element => {
          this.toastr.error(element.message,"Problema Exclusão");
        });
      });
      this.hideModal();
  }

  showModal(id: string) {
    this.idToRemove = id;
    this.ui.setActive('modal');
  }

  hideModal() {
    this.ui.setInactive('modal');
  }
  

  getCustomers()  {
    this.dataService.getAllCustomers().pipe(first()).subscribe(
        data => {
             this.customers = data;
             this.isLoaderActive=false;
        },
        error => {
          this.errors = error.error
            this.isLoaderActive=false;
        });
  }

}
