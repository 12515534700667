import { Routes } from '@angular/router';
import { ClientDetailComponent } from './client-detail/client-detail.component';
import { ClientListComponent } from './client-list/client-list.component';

export const rootRoutesConfig: Routes = [
    { path: '', component: ClientListComponent},
    { path: 'addclient', component: ClientDetailComponent},
    { path: 'editClient/:id', component: ClientDetailComponent}
]


