import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class DataService {
    constructor(private http: HttpClient) { }

    createCustomer(data:any){
        return this.http.post<any>(`${environment.serviceUrl}/customers/register`, data)
            .pipe(map(customer => {
            return customer;
        }));
    }

    getCustomerById(id:string)
    {
        return this.http.get<any>(`${environment.serviceUrl}/customers/`+ id);
    }

    getAllCustomers()
    {
        return this.http.get<any>(`${environment.serviceUrl}/customers`);
    }

    deleteCustomer(id:string){
        return this.http.delete(`${environment.serviceUrl}/customers/` + id);
    }

    updateCustomer(data:any,id:string){
        return this.http.put<any>(`${environment.serviceUrl}/customers/` + id, data)
            .pipe(map(request => {
            return request;
        }));
    }

}