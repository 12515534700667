import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { DataService } from './services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [DataService]
})
export class AppComponent implements OnInit {
  public errors: any[] = [];
  isLoaderActive = true;
  customers: any;

  constructor(private dataService: DataService)
  {

  }

  ngOnInit() {
    this.isLoaderActive=true;
    this.getCustomers();
  }

  getCustomers()  {
    this.dataService.getAllCustomers().pipe(first()).subscribe(
        data => {
             this.customers = data;
             this.isLoaderActive=false;
        },
        error => {
          console.log("Bugou");
          this.errors = error.error
            console.log(error);
            this.isLoaderActive=false;
        });
  }
}
